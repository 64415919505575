import React, { Fragment, ReactElement, ReactNode, useState } from 'react';

function Popover({
	showDefault = false,
	children,
}: {
	showDefault?: boolean,
	children: ReactNode,
}): ReactElement {
	const [show, setShow] = useState<boolean>(showDefault);
	const toggleShow: () => void = () => {
		setShow(!show);
	}

	return show ?
		<div className='absolute top-0 left-0 right-0 bottom-0 z-50 p-8 bg-gdk-dark-grey lg:bg-transparent lg:py-16 lg:px-36 xl:px-56 2xl:px-96'>
			<div className='@container h-full w-full flex flex-col lg:p-8 lg:border lg:border-gdk-yellow lg:bg-gdk-dark-grey overflow-x-auto'>
				<div className='flex justify-end'>
					<p className="text-2xl red mb-0 cursor-default hover:cursor-pointer" onClick={toggleShow}>X</p>
				</div> 
				{children}
			</div>
		</div>
		: <Fragment />
}

export default Popover;