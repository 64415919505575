import React, { useEffect, useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import "./ProjectCard.css";

interface ProjectCardProps {
	bg?: string;
	logos?: string[][];
	logoBg?: string;
	border?: string;
	name?: string;
	description?: string;
	onClickFn: () => void;
}

function isValidHexa(str: string): boolean {
	const hexaRegex = /^#[0-9A-Fa-f]{3,6,8}$/;
	return hexaRegex.test(str);
}

const ProjectCard: React.FC<ProjectCardProps> = ({
	bg = "#FFF",
	logos = [],
	logoBg = "#000000A9",
	border = "none",
	name = "My Project",
	description = "A short description of you project goes here.",
	onClickFn
}) => {
	let hexaBg = isValidHexa(bg);
	let containerRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!containerRef.current) return;
		if (hexaBg) {
			containerRef.current.style.backgroundColor = bg;
		} else {
			containerRef.current.style.backgroundImage = "url('" + bg + "')";
			containerRef.current.style.backgroundSize = "cover";
			containerRef.current.style.backgroundPosition = "center center";
		}
	});

	useEventListener('click', onClickFn, containerRef);

	return (
		<div className='aspect-square w-full cursor-pointer' ref={containerRef}>
			<div className='h-full flex flex-col justify-end'>
				<div className='flex flex-col justify-end' style={{ backgroundColor: logoBg }}>
					<h6 className='px-4 pt-2'>{name}</h6>
					<p className='text-[#CFDBD5] px-4'>{description}</p>
					<div className='w-full p-4 pt-2' style={{ display: logos.length ? "flex" : "none" }}>
						{logos.map((logo, i) => { return <img className="flex h-8 mr-4 last:mr-0" src={logo[0]} key={i} alt={logo[1]}/> })}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjectCard;

// This popover component accepts two required props: children and content. The children prop is used to render the trigger element for the popover (e.g. a button), and the content prop is used to render the content that will be displayed in the popover. The component also accepts an optional prop called placement, which determines the position of the popover relative to the trigger element.
// The component uses a state variable called isOpen to track whether the popover is currently open or closed. When the trigger element is clicked, the handleClick function is called, which toggles the value of isOpen. If isOpen is true, the popover is rendered with the appropriate CSS class based on the placement prop.




