import { useState, useRef, Fragment } from 'react';
import { motion, useScroll, useTransform } from "framer-motion";
import logo from './logo.svg';
import './App.css';
import { Heading } from './componentLibrary';
import PopoverContactForm from './PopoverContactForm';
import ProjectCard from './ProjectCard/ProjectCard';
import Questionnaire from './Components/Questionnaire/Questionnaire';
import Popover from './Components/Popover/Popover';

const LogoUrls = {
	React: ["./project-icons/react-icon.png", "The logo of React javascript library"],
	TailwindCSS: ["./project-icons/tailwind-css-icon.png", "The logo of Tailwaind CSS library"],
	Redux: ["./project-icons/redux-icon.png", "The logo of Redux JS"],
	AngularJS: ["./project-icons/angular-1-icon.png", "The logo of AngularJS (Angular 1)"],
	Electron: ["./project-icons/electron-icon.png", "The logo of Electron JS"],
}

function getCurrentYear(): number {
	return new Date().getFullYear();
}

function getYearsSince2015(): number {
	const currentYear = new Date().getFullYear();
	return currentYear - 2015;
}

const CONTACT_FORM_DEFAULTS = {
	title: <h3 className='leading-8'>Send me a message</h3>,
	text: "You're so close to getting to help your project needs, complete the form below and once I've reviewed your requirements I'll be in touch.",
	endpoint: "api/website/sendMessage"
}
function App() {
	let headerRef = useRef(null);
	let { scrollYProgress } = useScroll({
		target: headerRef
	});
	let headerY = useTransform(scrollYProgress, [0, 1], ["0%", "80%"])
	const [showContactForm, setShowContactForm] = useState<boolean>(false);
	let contactFormProps = { ...CONTACT_FORM_DEFAULTS, show: showContactForm, toggleShow: () => { setShowContactForm(false) } };
	
	return (
		<Fragment>
			<PopoverContactForm {...contactFormProps} />
			<motion.div ref={headerRef} className='flex flex-col bg1 bg-repeat bg-90% sm:bg-70% md:bg-50% lg:bg-30%' style={{ backgroundPositionY: headerY }}>
				<div className='h-screen flex flex-col'>
					<header className='light-grey-bg'>
						<div className='flex justify-center p-4'>
							<div className='lg:w-1/4'>
								<motion.img animate={{ rotate: [0, 360] }} transition={{ ease: "linear", repeat: Infinity, duration: 20 }} src={logo} className="w-16 aspect-square pointer-events-none" alt="logo" />
							</div>
							<div className='hidden lg:block lg:w-3/4'></div>
						</div>
					</header>
					<div className='flex flex-col justify-center flex-1 mx-auto px-4 lg:px-20 xl:p-0 max-w-5xl'>
						<div className='mx-auto lg:grid lg:grid-cols-[5fr_7fr] lg:place-content-center'>
							<div className='mb-14 lg:mb-0 lg:pl-0 lg:flex lg:flex-col lg:justify-center'>
								<Heading text="Kris" level={1} />
								<Heading text="Goddard" level={1} />
								<div className='red-bg mt-4 mb-4 h-1 w-2/3 sm:w-1/2 lg:w-2/3'></div>
								<Heading text='Goddak' level={2} className="yellow"/>
							</div>
							<div>
								<Heading className="mb-6 lg:mb-10" text='- A brief introduction' level={5} />
								<Heading className="pb-4" text={'I am a web developer based in Berkshire, UK, with over ' + getYearsSince2015() + ' years of experience.'} level={3} />
								<p className='hidden lg:block lg:pb-4'>I have worked on a wide range of projects for clients from small business websites to custom web applications. I am dedicated to providing high-quality work and am constantly learning and exploring new technologies and techniques to stay at the forefront of the web development industry. Whether you are looking to create a new website or update and improve an existing one, I am here to help bring your vision to life. Let's work together to create a stunning and user-friendly website that will help your business succeed online.</p>
								<a href='#' onClick={() => { setShowContactForm(true) }}>Hire me.</a>
							</div>
						</div>
					</div>
				</div>
				<div className='min-h-screen light-grey-bg flex items-center lg:flex-col lg:justify-center lg:py-24'>
					<div className='mx-auto p-4 max-w-5xl lg:grid lg:grid-cols-[5fr_7fr] lg:place-content-center lg:mb-16'>
						<div className='mx-auto lg:pr-4'>
							<Heading className="mb-6 lg:mb-10" text='- Get in touch' level={5} />
							<Heading className="pb-4" text='One good conversation can shift the direction of change forever.' level={3} />
							<p className="pb-4">I'm really interested in helping new business startups develop their online identity. I can help build your brand, let's create something big together!</p>
							<div>
								<a className="pr-4" href='mailto:kris@goddak.co.uk?subject=Collaboration request'>Email me.</a>
								<a href='#' onClick={() => { setShowContactForm(true) }}>Contact form.</a>
							</div>
						</div>
						<div className='hidden lg:block'>
							<Heading className="lg:pb-4" text='A conversation is a dialogue, not a monologue. I am here to help guide you on your journey.' level={3} />
							<p className="lg:pb-4">I have a wealth of knowledge and resources curated throughout my years of professional web development, these assets allow me to be flexible to your needs & requirements.</p>
							<p className="lg:pb-4">If you know your needs/wants then we're off to a great start. Send me your requirements & assets using the contact links provided and I'll get in contact as soon as I've reviewed them.</p>
							<p className="lg:pb-4">If you need assistance determining your needs/wants, you've come to the right place. Check out the digital resources below, designed to help you identify what you need and for me to assist you. If you prefer a more personal approach, a consultation service is also available for a one-on-one chat.</p>
						</div>
					</div>
					<div className='hidden mb-8 max-w-5xl mx-auto lg:flex'>
						<div className='w-64 xl:w-80 aspect-square p-4 m-4 yellow-bg dark-grey flex flex-col items-center justify-center'>
							<img src={logo} className="w-1/4 aspect-square mb-4" alt="logo" />
							<Heading className='text-center ' text='Requirements questionaire' level={3} />
							<p>Takes 10/15 minutes to complete.</p>
						</div>
						<div className='w-64 xl:w-80 aspect-square p-4 m-4 yellow-bg dark-grey flex flex-col items-center justify-center'>
							<img src={logo} className="w-1/4 aspect-square mb-4" alt="logo" />
							<Heading className='text-center ' text='Web/App project checklist' level={3} />
							<p>A thorough project checklist.</p>
						</div>
						<div className='w-64 xl:w-80 aspect-square p-4 m-4 yellow-bg dark-grey flex flex-col items-center justify-center'>
							<img src={logo} className="w-1/4 aspect-square mb-4" alt="logo" />
							<Heading className='text-center ' text='Consultation service' level={3} />
							<p>Request a personal consultation online.</p>
						</div>
					</div>
				</div>
				<div className='min-h-screen flex flex-col justify-center py-24'>
					<div className='mx-auto p-4 max-w-5xl mb-24'>
						<div className='mx-auto lg:pr-4'>
							<Heading className="mb-6 lg:mb-10" text="- Things I've made" level={5} />
							<Heading className='pb-4' text='Creativity is seeing what others see and thinking what no one else ever thought.' level={3} />
							<p className='lg:hidden'>Here's a selection of my recent web development projects, each with a description of the work and technologies used. I'm always learning and exploring new tech. If you have questions or want to discuss a potential project, please reach out.</p>
							<p className='hidden lg:block'>In this section of my website, you will find a selection of my recent web development projects. Each project is accompanied by a brief description of the work I did and the technologies I used. I have included a range of projects to showcase my skills and experience, and to give you an idea of the types of work I am capable of. I am constantly learning and exploring new technologies to stay at the forefront of the web development industry, and I am always looking for new and interesting projects to work on. If you have any questions or would like to discuss a potential project, please don't hesitate to contact me.</p>
						</div>
					</div>
					<div className='container mb-8 w-full max-w-5xl mx-auto flex flex-wrap justify-center'>
						<div className='w-4/5 md:w-96 lg:w-80 dark-grey p-4'>
							<ProjectCard name="Daily Dino" description="Check the app daily to add a new dinosaur to your collection." bg="./project-images/daily-dino/splash.jpg" logos={[LogoUrls.React, LogoUrls.TailwindCSS]} onClickFn={() => null} />
						</div>
						<div className='w-4/5 md:w-96 lg:w-80 dark-grey p-4'>
							<ProjectCard name="QRCode generator" description="QR generator with premium link shortening & tracking features." bg="./project-2.png" logos={[LogoUrls.React]} onClickFn={() => null} />
						</div>
						<div className='w-4/5 md:w-96 lg:w-80 dark-grey p-4'>
							<ProjectCard name="AirSwap Marketplace" description="P2P web frontend for AirSwap decentralized trading systems." bg="./project-images/airswap-marketplace/splash.jpg" logos={[LogoUrls.React, LogoUrls.Redux]} onClickFn={() => null} />
						</div>
						<div className='w-4/5 md:w-96 lg:w-80 dark-grey p-4 hidden md:block'>
							<ProjectCard name="PlaceGoddak" description="Image API serving images of Goddak for use as placeholders." bg="./project-1.png" logos={[LogoUrls.React]} onClickFn={() => null} />
						</div>
						<div className='w-4/5 md:w-96 lg:w-80 dark-grey p-4 hidden lg:block'>
							<ProjectCard name="Poll API & Hosting" description="Display polls in your project with my hosted polls & api services." bg="./project-1.png" logos={[LogoUrls.React]} onClickFn={() => null} />
						</div>
						<div className='w-4/5 md:w-96 lg:w-80 dark-grey p-4 hidden lg:block'>
							<ProjectCard name="Digital Signage - Pixelnebula" description="Front-end developer working on UI/UX and templates." bg="./project-images/pixelnebula/splash.jpg" logos={[LogoUrls.AngularJS, LogoUrls.React, LogoUrls.Redux, LogoUrls.Electron]} onClickFn={() => null} />
						</div>
					</div>
				</div>
				<div className='min-h-screen flex flex-col justify-center py-24 light-grey-bg'>
					<div className='mx-auto p-4 max-w-5xl'>
						<div className='mx-auto'>
							<Heading className="mb-6 lg:mb-10" text="- I'm a mentor" level={5} />
							<Heading className='pb-4' text='As you grow older you will discover that you have two hands. One for helping yourself, the other for helping others.' level={3} />
							<p className='pb-4 lg:hidden'>I'm a mentor for web developers, here to help you improve your skills, advance your career, and achieve your goals. I offer personalized guidance, support, and advice on the latest technologies and best practices. Whether you're just starting out or looking to take your career to the next level, I'm here to help you succeed as a web developer.</p>
							<p className='hidden pb-4 lg:block'>As a mentor for web developers, I can help you improve your skills and advance your career. With my experience and knowledge, I can provide personalized guidance and support to help you overcome challenges and reach your goals. I can help you stay up to date with the latest technologies and best practices, and provide valuable advice on how to succeed in the competitive world of web development. Whether you're just starting out or looking to take your career to the next level, I can help you achieve your goals and succeed as a web developer.</p>
							<a href='#' onClick={() => { setShowContactForm(true) }}>Become a mentee.</a>
						</div>
					</div>
				</div>
				<div className='py-24 flex justify-center'>
					<Popover showDefault={false} children={
						<div className='h-full w-full flex flex-col'>
							<h1 className='mb-16'>Requirements Questionnaire</h1>
							<Questionnaire/>
						</div>
					} />
					<div className='hidden lg:block w-1/4'></div>
					<div className='w-3/4 lg:w-1/2'>
						<p className='text-center'>&copy; {getCurrentYear()} Kris "Goddak" Goddard</p>
					</div>
					<div className='hidden lg:block w-1/4'></div>
				</div>
			</motion.div>
		</Fragment>
	);
}

export default App;
