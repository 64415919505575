export interface Question {
  text: string;
  type: 'text' | 'radio' | 'checkbox';
  options?: string[];
}

export interface Section {
  title: string;
  questions: Question[];
}

const questions: Section[] = [
  {
    title: 'Company Information',
    questions: [
      { text: 'What is the name of your company?', type: 'text' },
      { text: 'What is the website URL for your company?', type: 'text' },
      { text: 'What is the industry your company operates in?', type: 'text' },
      { text: 'What is your company’s mission statement?', type: 'text' },
    ],
  },
  {
    title: 'Competitors',
    questions: [
      {
        text: 'Who are your main competitors?',
        type: 'text',
      },
      {
        text: 'What sets your company apart from your competitors?',
        type: 'text',
      },
    ],
  },
  {
    title: 'Target Audience',
    questions: [
      { text: 'Who is your target audience?', type: 'text' },
      {
        text: 'What are the characteristics of your target audience?',
        type: 'text',
      },
      {
        text: 'What are the goals of your target audience?',
        type: 'text',
      },
    ],
  },
  {
    title: 'Design Requirements',
    questions: [
      {
        text: 'What is the primary goal of your website?',
        type: 'text',
      },
      {
        text: 'What message do you want to convey through your website?',
        type: 'text',
      },
      {
        text: 'What kind of images or graphics do you want to include on your website?',
        type: 'text',
      },
      {
        text: 'Do you have any color preferences for your website?',
        type: 'text',
      },
      {
        text: 'Do you have any specific font preferences for your website?',
        type: 'text',
      },
    ],
  },
  {
    title: 'Functionality Requirements',
    questions: [
      {
        text: 'What features do you want to include in your website?',
        type: 'text',
      },
      {
        text: 'Do you need any e-commerce functionality for your website?',
        type: 'radio',
        options: ['Yes', 'No'],
      },
      {
        text: 'Do you need a blog section on your website?',
        type: 'radio',
        options: ['Yes', 'No'],
      },
      {
        text: 'Do you need any specific integrations with external services?',
        type: 'text',
      },
    ],
  },
  {
		title: "Timeline & Budget",
		questions: [
			{
				text: "What is the desired completion date for the website?",
				type: "text"
			},
			{
				text: "What is the timeline for any future updates or changes to the website?",
				type: "text"
			},
			{
				text: "What is the budget for the website development?",
				type: "text"
			},
			{
				text: "Are there any specific features or functionality that are essential and must be included in the budget?",
				type: "text",
			},
			{
				text: "Are there any specific features or functionality that can be deferred or excluded if necessary?",
				type: "text",
			},
		],
	},
];

export default questions;