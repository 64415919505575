import React, { ChangeEvent, FormEventHandler, Fragment, useEffect, useState } from 'react';
import submitForm from '../../Helpers/Functions/submitForm';
import questions from '../../Helpers/Questionnaire/Questions';

interface Answer {
  [key: string]: string | boolean;
}

const Questionnaire = () => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  const [currentSection, setCurrentSection] = useState(questions[currentSectionIndex]);
	const [answers, setAnswers] = useState<Answer>({});


  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
		setAnswers(prevAnswers => {
			const newAnswers = {
				...prevAnswers
			};
			if (type === 'radio' || type === 'checkbox') {
				newAnswers[name] = checked;
			} else {
				newAnswers[name] = value;
			}
			return newAnswers;
		});
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
		event.preventDefault();
		submitForm('api/website/sendMessage', answers)
			.then((response) => {
				console.log('SUBMITTED!', response);
			})
			.catch((err) => {
				console.log('CHOMP WOMP..', err);
				return err;
			});
  };

  const handleNext = () => {
    setCurrentSectionIndex(currentSectionIndex + 1);
  };
	
  const handlePrevious = () => {
		setCurrentSectionIndex(currentSectionIndex - 1);
  };
	
	useEffect(() => {
		setCurrentSection(questions[currentSectionIndex]);
	}, [currentSectionIndex])

  return (
		<Fragment>
			<div className="flex justify-between items-center">
				<h2 className="text-xl md:text-2xl font-bold">{currentSection.title}</h2>
				<div className='flex flex-col'>
					<p>Progress:</p>
					<div className="w-36 h-2 rounded bg-gray-300">
						<div
							className="h-full w-full rounded bg-gdk-red origin-left transition-transform"
							style={{ transform: `scaleX(${currentSectionIndex / (questions.length - 1)})` }}
						/>
					</div>
				</div>
			</div>
			<form className='flex flex-col flex-1' onSubmit={handleSubmit}>
				<div className='flex flex-col justify-center flex-1'>
					{currentSection.questions.map((question, index) => (
						<div key={index} className="flex flex-col w-full mb-4">
							<p className="mb-2">{question.text}</p>
							{question.type === 'text' ? (
								<input
									type="text"
									className="px-4 py-2 border rounded w-full"
									name={`${questions[currentSectionIndex].title.toLowerCase().replace(/\s+/g, '-')}-${index}`}
									onChange={handleChange}
									value={answers[`${questions[currentSectionIndex].title.toLowerCase().replace(/\s+/g, '-')}-${index}`] as string || ''}
								/>
							) : question.type === 'radio' ? (
								<div className="flex">
									{question.options?.map((option, index) => (
										<label
											key={index}
											className="px-4 py-2 border rounded mr-4"
										>
											<input
												type="radio"
												name={`${questions[currentSectionIndex].title.toLowerCase().replace(/\s+/g, '-')}-${index}`}
												value={option}
												onChange={handleChange}
												checked={answers[`${questions[currentSectionIndex].title.toLowerCase().replace(/\s+/g, '-')}-${index}`] === option}
											/>
											{option}
										</label>
									))}
								</div>
							) : (
								<input
									type="checkbox"
									name={`${questions[currentSectionIndex].title.toLowerCase().replace(/\s+/g, '-')}-${index}`}
									onChange={handleChange}
									checked={answers[`${questions[currentSectionIndex].title.toLowerCase().replace(/\s+/g, '-')}-${index}`] as boolean}
								/>
							)}
						</div>
					))}
				</div>
				<div className="flex justify-around">
					<button
						type="button"
						className="px-10 py-2 rounded bg-gdk-red text-white border-none font-semibold"
						onClick={handlePrevious}
						disabled={currentSectionIndex === 0}
					>
						Previous
					</button>
					<button
						type="button"
						className="px-10 py-2 rounded bg-gdk-yellow text-gdk-dark-grey border-none font-semibold"
						onClick={handleNext}
						disabled={currentSectionIndex === questions.length - 1}
						style={{ "display":  currentSectionIndex === questions.length - 1 ? "none" : "block" }}
					>
						Next
					</button>
					<button
						type="submit"
						className="px-10 py-2 rounded bg-green-600 text-white border-none font-semibold"
						style={{ "display":  currentSectionIndex !== questions.length - 1 ? "none" : "block" }}
					>
						Submit
					</button>
				</div>
			</form>
		</Fragment>
	);


};

export default Questionnaire;
