import axios, { AxiosRequestConfig } from "axios";

const DEFAULT_AXIOS_REQUEST_CONFIG: AxiosRequestConfig<Object> = { proxy: { host: "localhost", port: 5000 } }

const submitForm: (endpoint: string, formState: Object, axiosConfig?: AxiosRequestConfig<Object>) => Promise<void> = (endpoint, formState, axiosConfig) => {
	return axios.post(endpoint, formState, axiosConfig ? axiosConfig : DEFAULT_AXIOS_REQUEST_CONFIG)
		.then((response) => {
			console.log('SUCCESS!', response.status, response.statusText);
			return response;
		})
		.catch((err) => {
			console.log('FAILED...', err);
			return err;
		});
}

export default submitForm;