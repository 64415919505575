import React, { Fragment, ReactElement, ReactNode, useState } from 'react';
import axios from 'axios';

type FormState = {
	senderName: string,
	senderEmail: string,
	senderPhone: string,
	message: string
}

const DEFAULT_FORM_STATE: FormState = {
	senderName: "",
	senderEmail: "",
	senderPhone: "",
	message: ""
}

function PopoverContactForm({
	show,
	toggleShow,
	title,
	text,
	endpoint
}: {
	show: boolean,
	toggleShow: () => void,
	title?: string | ReactNode,
	text?: string | ReactNode,
	endpoint: string
}): ReactElement {
	// Create form state & changeHandlerFn
	const [formState, setFormState] = useState<FormState>({ ...DEFAULT_FORM_STATE });
	const onFormInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFormState({ ...formState, [e.currentTarget.name]: e.currentTarget.value });
	};


	// Get the title & text children ready.
	let TitleChild = title;
	if (title && typeof title === "string") {
		TitleChild = <h3 className='leading-8'>{title}</h3>
	}
	let TextChild = text;
	if (text && typeof text === "string") {
		TextChild = <p className='mb-4'>{text}</p>
	}

	// Handle what the form does when submitted
	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		toggleShow();
		// Actually do something here.
		axios.post(endpoint, formState, { proxy: { host: "localhost", port: 5000 } })
			.then((response) => {
				console.log('SUCCESS!', response.status, response.statusText);
			})
			.catch((err) => {
				console.log('FAILED...', err);
			});
	}

	return show ?
		<div className='@container absolute top-0 left-0 right-0 min-h-screen z-10 md:flex md:items-center lg:max-w-5xl lg:mx-auto'>
			<div className='h-full w-full p-4 @md:p-8 flex flex-col dark-grey-bg @lg:border @lg:border-[#F3C33F]'>
				<div className='flex justify-between'>
					{TitleChild}
					<p className="text-2xl red mb-0 cursor-default hover:cursor-pointer" onClick={toggleShow}>X</p>
				</div> 
				<div className='h-1 w-6 mt-[-0.4em] mb-4 yellow-bg'></div>
	           {TextChild}
			   <form className="flex flex-col @4xl:flex-row @4xl:flex-wrap @4xl:justify-between" onSubmit={onSubmit}>
		            <div className='flex flex-col mb-4 @xl:flex-[0_0_48%]'>
		                <label className='cf-input-label' htmlFor="senderName">Name:</label>
		                <input className='p-2 dark-grey' name="senderName" id="senderName" type="text" value={formState['senderName']} onChange={onFormInputChange}/>
		            </div>
		            <div className='flex flex-col mb-4 @xl:flex-[0_0_48%]'>
		                <label className='cf-input-label' htmlFor="senderEmail">Email:</label>
		                <input className='p-2 dark-grey' name="senderEmail" id="senderEmail" type="text" value={formState['senderEmail']} onChange={onFormInputChange}/>
		            </div>
		            <div className='flex flex-col mb-4 @xl:flex-[0_0_48%]'>
		                <label className='cf-input-label' htmlFor="senderPhone">Phone:</label>
		                <input className='p-2 dark-grey' name="senderPhone" id="senderPhone" type="text" value={formState['senderPhone']} onChange={onFormInputChange}/>
		            </div>
		            <div className='flex flex-col mb-4 @xl:flex-[0_0_100%]'>
		                <label className='cf-input-label' htmlFor="message">Message:</label>
		                <textarea className='p-2 dark-grey' name="message" id="message" rows={8} value={formState['message']} onChange={onFormInputChange}/>
		            </div>
		            <button className="py-2 px-8 yellow-bg dark-grey border-none" type='submit'>Send message</button>
		        </form>
			</div>
		</div>
		// <div className='cf-container'>
		//     <div className="cf-popover">
		//         <div className='cf-title-row'>
		//             {TitleChild}
		//             <p className="cf-close" onClick={toggleShow}>X</p>
		//         </div>
		//         <div className='cf-accent'></div>
		//         {TextChild}
		        // <form className="cf-form" onSubmit={onSubmit}>
		        //     <div className='cf-field cf-field-50'>
		        //         <label className='cf-input-label' htmlFor="senderName">Name:</label>
		        //         <input className='cf-text-input' name="senderName" id="senderName" type="text" value={formState['senderName']} onChange={onFormInputChange}/>
		        //     </div>
		        //     <div className='cf-field cf-field-50'>
		        //         <label className='cf-input-label' htmlFor="senderEmail">Email:</label>
		        //         <input className='cf-text-input' name="senderEmail" id="senderEmail" type="text" value={formState['senderEmail']} onChange={onFormInputChange}/>
		        //     </div>
		        //     <div className='cf-field cf-field-50'>
		        //         <label className='cf-input-label' htmlFor="senderPhone">Phone:</label>
		        //         <input className='cf-text-input' name="senderPhone" id="senderPhone" type="text" value={formState['senderPhone']} onChange={onFormInputChange}/>
		        //     </div>
		        //     <div className='cf-field cf-field-50'>
		        //     </div>
		        //     <div className='cf-field cf-field-100'>
		        //         <label className='cf-input-label' htmlFor="message">Message:</label>
		        //         <textarea className='cf-textarea-input' name="message" id="message" rows={8} value={formState['message']} onChange={onFormInputChange}/>
		        //     </div>
		        //     <button className="cf-submit-button" type='submit'>Send message</button>
		        // </form>
		//     </div>
		// </div> :
		: <Fragment />
}

export default PopoverContactForm;