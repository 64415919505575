import React, { createElement } from "react";

type HeadingProps = {
  level: number;
  text: string;
  className?: string
};

const Heading: React.FC<HeadingProps> = ({ level, text, className }) => {
  const HeadingTag = createElement(
    `h${level}`,
    { className },
    text
  )
  
  return HeadingTag;
};

type ButtonProps = {
  text: string;
  onClick: () => void;
  color?: string;
  fontWeight?: number | string;
  padding?: string;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  color,
  fontWeight,
  padding,
  className,
}) => {
  const style: React.CSSProperties = {};

  if (color) {
    style.color = color;
  }

  if (fontWeight) {
    style.fontWeight = fontWeight;
  }

  if (padding) {
    style.padding = padding;
  }

  return <button className={className} style={style} onClick={onClick}>{text}</button>;
};

type InputProps = {
  value: string;
  onChange: (value: string) => void;
};

const Input: React.FC<InputProps> = ({ value, onChange }) => (
  <input value={value} onChange={(e) => onChange(e.target.value)} />
);

type TextAreaProps = {
  value: string;
  onChange: (value: string) => void;
};

const TextArea: React.FC<TextAreaProps> = ({ value, onChange }) => (
  <textarea value={value} onChange={(e) => onChange(e.target.value)} />
);


/*
direction: The direction in which the flex items are placed in the flex container. This can be "row", "row-reverse", "column", or "column-reverse". The default value is "row".
wrap: Whether the flex items should wrap onto multiple lines, and in which direction. This can be "nowrap", "wrap", or "wrap-reverse". The default value is "nowrap".
justifyContent: How the flex items are aligned along the main axis (horizontally for "row" or "row-reverse" and vertically for "column" or "column-reverse"). This can be "flex-start", "flex-end", "center", "space-between", or "space-around". The default value is "flex-start".
alignItems: How the flex items are aligned along the cross axis (vertically for "row" or "row-reverse" and horizontally for "column" or "column-reverse"). This can be "flex-start", "flex-end", "center", "baseline", or "stretch". The default value is "stretch".
alignContent: How the lines of flex items are aligned within the flex container when there are multiple lines of flex items. This can be "flex-start", "flex-end", "center", "space-between", `"
*/

type FlexProps = {
  flex?: string,
  flexDirection?: 'row' | 'column',
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse',
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch',
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
  className?: string,
  children: React.ReactNode
}

const Flex: React.FC<FlexProps> = ({
  flex,
  flexDirection,
  flexWrap,
  alignItems,
  justifyContent,
  className,
  children 
}) => {
  const style: React.CSSProperties = {
    display: "flex",
    flexDirection,
    flexWrap,
    flex,
    justifyContent,
    alignItems,
  };

  return (
    <div
      style={style}
      className={className}
    >
      {children}
    </div>
  );
}

type LinkProps = {
  text: string;
  url: string;
  target: "_blank" | "_self";
  button: boolean;
  className: string;
};

const Link: React.FC<LinkProps> = ({ text, url, target, button, className }) => {
  const Element = button ? "button" : "a";

  return (
    <Element
      href={url}
      target={target}
      className={className}
    >
      {text}
    </Element>
  );
};

Link.defaultProps = {
  text: "Click me",
  target: "_self",
  button: false,
};

const Components = { Heading, Button, Input, TextArea, Flex, Link };
export { Heading, Button, Input, TextArea, Flex, Link };
export default Components;
